
  import { defineComponent } from 'vue'
  import useBreakdownDetail from "@/hooks/payment/DealerBreakdown/useBreakdownDetail";
  import { ColumnProps } from 'ant-design-vue/es/table/interface';
  type Key = ColumnProps['key'];
  const tableWidth = window.innerWidth
  const tableHeight = window.innerHeight-402
  import UploadDetail from '@/views/Payment/DealerBreakdown/detail/uploadDetail.vue'
  // import Pagination from "@/components/Pagination.vue";
  import TableWrapper from "@/components/TableWrapper.vue";
  import { useStore } from "vuex";
  export default defineComponent({
    name: 'DealerVendorCodeSet',
    components: {
      UploadDetail,
      // Pagination,
      TableWrapper
    },
    props: {},
    setup() {
      const store = useStore()
      const {
        searchParams, entityList, getTableData, rowSelection,
        columnsData, tableData, pageChange, sizeChange, pageSize, pageNumber, total,
        isUploadVisible, dealerDetailApproveId, downloadClick, uploadClick, closeUpload, refreshTable,
        fileClick, paymentClaimExport, demoCarDeductionExport
      } = useBreakdownDetail()
      getTableData()
      const handlePageChange = (page: number,pageSize: number) =>{
        pageChange(page, pageSize)
      }
      const handleSizeChange = (page: number,pageSize: number) =>{
        sizeChange(page, pageSize)
      }
      return {
        store,
        searchParams,
        entityList,
        tableWidth,
        tableHeight,
        columnsData,
        tableData,
        rowSelection,
        pageSize,
        pageNumber,
        total,
        handlePageChange,
        handleSizeChange,
        isUploadVisible,
        dealerDetailApproveId,
        downloadClick,
        uploadClick,
        closeUpload,
        refreshTable,
        fileClick,
        paymentClaimExport,
        demoCarDeductionExport
      }
    }
  })
  