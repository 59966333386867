import { ref, reactive, computed } from "vue";
import * as API from "@/API/payment";
import downloadFile from '@/utils/payment/downloadFile'
// import moment from 'moment';
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { downloadFromStream } from '@/utils/payment/downloadFile'

type Key = ColumnProps['key'];

type SearchParams = {
    payoutRound: string;
    swtCode: string;
    dealerCode: string;
    dealerNameCn: string;
    dealerDetailApproveId: string;
}

export interface SalesTableData {
    id: string;
    entity: string;
    amountWVat: string;
    amountWOVat: string;
    status: string;
    fileRespVOList: { fileNameCN: string; fileFlag: boolean; fileCode: string }[];
}

const useBreakdownDetail = () => {
    const store = useStore()
    const searchParams = reactive<SearchParams>(store.state.payment.breakdownRow)
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const entityList = ref<string[]>([])
    const columnsData = computed(() => {
        return [
            { title: "批发来源", dataIndex: "entity"},
            { title: "含税付款金额合计", dataIndex: "amountWVat",customRender: useThousandsAndTwoDecimal(),width: 190, align: "right"},
            { title: "不含税付款金额合计", dataIndex: "amountWOVat",customRender: useThousandsAndTwoDecimal(),width: 190, align: "right"},
            { title: "销售激励付款批发来源", dataIndex: "download",slots: { customRender: 'download' }, width: 650 },
            { title: "上传", dataIndex: "upload",slots: { customRender: 'upload' }, width: 100},
            { title: "上传状态", dataIndex: "status", align: "center"}
        ]
    })
    const tableData = ref<SalesTableData[]>()
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: SalesTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: SalesTableData[]) => {
            rowSelection.selectedRowKeys = selectedRowKeys;
            rowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '30px'
    })
    const getTableData = () => {
        const params = {
            payoutRound: searchParams.payoutRound,
            dealerCode: searchParams.dealerCode
        }
        API.breakDownDetailList(params).then(data => {
            tableData.value = data
        })
        // tableData.value = [
        //     {
        //         id: '383',
        //         entity: 'MBCL',
        //         amountWVat: '1000',
        //         amountWOVat: '9999',
        //         status: '',
        //         fileRespVOList: []
        //     }
        // ]
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const isUploadVisible = ref<boolean>(false)
    const dealerDetailApproveId = ref('')
    const downloadClick = () => {
        if (rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        const entityFileMap = {}
        const selectData = rowSelection.selectedRowData
        for (let i = 0; i < rowSelection.selectedRowData.length; i ++) {
            entityFileMap[selectData[i].entity] = []
            for (let j = 0; j < selectData[i].fileRespVOList.length; j ++) {
                if (selectData[i].fileRespVOList[j].fileCode && selectData[i].fileRespVOList[j].fileFlag) {
                    entityFileMap[selectData[i].entity].push(selectData[i].fileRespVOList[j].fileCode)
                }
            }
            if (entityFileMap[selectData[i].entity].length === 0) {
                entityFileMap[selectData[i].entity] = undefined
            }
        }
        const params = {
            url: '/ptapi/export/dealer/breakdown/downloadFile/dealer',
            method: 'post',
            params: {
                dealerCode: searchParams.dealerCode,
                entityFileMap,
                payoutRound: searchParams.payoutRound
            } 
        }
        downloadFile(params).catch(msg =>
            message.warning(msg)
        )
    }
    const uploadClick = (record: SalesTableData) => {
        if (record.status === '已通过' || record.status === '') return
        dealerDetailApproveId.value = record.id
        isUploadVisible.value = true
    }
    const closeUpload = (param: { isUploadVisible: boolean }) => {
        isUploadVisible.value = param.isUploadVisible
    }
    const refreshTable =()=>{
        closeUpload({ isUploadVisible: false })
        getTableData()
    }

    const fileClick = (record: SalesTableData, fileCode: string, fileFlag: boolean) => {
        if (!fileFlag) {
            return
        }
        const entityFileMap = {}
        entityFileMap[record.entity] =  [ fileCode ]
        const params = {
            url: '/ptapi/export/dealer/breakdown/downloadFile/dealer',
            method: 'post',
            params: {
                dealerCode: searchParams.dealerCode,
                entityFileMap,
                payoutRound: searchParams.payoutRound
            } 
        }
        downloadFile(params).catch(msg =>
            message.warning(msg)
        )
    }

    const paymentClaimExport = async (dealerCode: string, entity: string, payoutRound: string, vehicleDetailsFileFlag: boolean) => {
        if (!vehicleDetailsFileFlag) {
          return
        }
        const data = await API.paymentClaimExport({
          dealerCode,
          entity,
          payoutRound
        })
        downloadFromStream(data)
    }

    const demoCarDeductionExport = async (entity: string) => {
      const data = await API.demoCarDeductionDealerDownload(searchParams.payoutRound, entity, searchParams.dealerCode)
      downloadFromStream(data)
    }

    return {
        searchParams,
        entityList,
        columnsData,
        tableData,
        rowSelection,
        getTableData,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        isUploadVisible,
        dealerDetailApproveId,
        downloadClick,
        uploadClick,
        closeUpload,
        refreshTable,
        fileClick,
        paymentClaimExport,
        demoCarDeductionExport
    }
}
export default useBreakdownDetail